import styled from "styled-components";
import { allColors } from "styles/colors";

const getColAttrs = () => {
  return {
    "data-a-v": "middle",
    "data-f-bold": "true",
    "data-b-a-s": "thin",
    "data-b-a-c": "#FFD8D8D8",
  };
};

export const TFoot = styled.tfoot.attrs(getColAttrs)`
  tr {
    background-color: rgb(38 146 94);
  }
  tr:first-child td:first-child {
    text-align: start !important;
  }
`;

export const TableCol = styled("td").attrs(getColAttrs)`
  border: 1px solid #f1f1f1;
  padding: 5px 8px;
  font-size: 14px;
  color: ${allColors.whiteColor};
  font-weight: 600;
  border-collapse: collapse;
  text-align: center;
  white-space: nowrap;
`;
