import Dislocation from "pages/dislocation/dislocation-add";
import AddDislocation from "pages/dislocation/components/add-dislocation";
import DistrictDislocationsList from "pages/dislocation/district";
import RegionDislocationsList from "pages/dislocation/region";
import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import RegionPlaceList from "pages/maskanlar/regions";
import RegionFarmersList from "pages/farmers/regions";
import DistrictFarmersList from "pages/farmers/district";
import FarmersInPlace from "pages/klasterlar-buyicha/abode/farmers";
import FermerPageForPlace from "pages/farmers/farmers-list";
import Jadval1 from "pages/new-excel/1-jadval";
import Jadval2 from "pages/new-excel/2-jadval";
import Jadval3 from "pages/new-excel/3-jadval";
import Jadval4 from "pages/new-excel/4-jadval";
import Jadval5 from "pages/new-excel/5-jadval";
import Jadval6 from "pages/new-excel/6-jadval";
import Users from "pages/users";
import AddUser from "pages/users/components/add-users";
import ExcelYuklash from "pages/excel-yuklash";
import UrugchilikRegionPage from "pages/uruglik-paxta/region";
import UrugchilikFermerPage from "pages/uruglik-paxta/farmers";
import UruglikDistrictPage from "pages/uruglik-paxta/district";
import UrugchilikYukXatiPage from "pages/uruglik-paxta/yuk-xati-pk17-page";
// import EditUser from "pages/users/components/edit-maskan";
const Auth = lazy(() => import("pages/auth"));
const NotFound = lazy(() => import("components/not-found"));
const RootLayout = lazy(() => import("RootLoyout"));
// const MassivPage = lazy(() => import("pages/hududlar-buyicha/massiv"));
const YukXatiPage = lazy(() =>
  import("pages/hududlar-buyicha/yuk-xati-pk17-page")
);
const FermerPage = lazy(() => import("pages/hududlar-buyicha/territory"));
const InformationPage = lazy(() => import("pages/information"));
const Klaster = lazy(() => import("pages/klaster-qushish"));
const AddCluster = lazy(() =>
  import("pages/klaster-qushish/components/add-cluster")
);
const KlasterClusterInAbodePage = lazy(() =>
  import("pages/klasterlar-buyicha/abode")
);
const KlasterDistrictInClusterPage = lazy(() =>
  import("pages/klasterlar-buyicha/cluster")
);
const KlasterRegionPage = lazy(() => import("pages/klasterlar-buyicha/region"));
// const KlasterDistrictPage = lazy(() =>
//   import("pages/klasterlar-buyicha/district")
// );
const RegionPage = lazy(() => import("pages/hududlar-buyicha/region"));
const DistrictPage = lazy(() => import("pages/hududlar-buyicha/district"));
const RejaQushishTuman = lazy(() => import("pages/reja-qoshish/tuman-uchun"));
const Maskan = lazy(() => import("pages/maskanlar"));
const AddMaskan = lazy(() => import("pages/maskanlar/components/add-maskan"));
const RejaQushishKlaster = lazy(() =>
  import("pages/reja-qoshish/klaster-uchun")
);

export const router = createBrowserRouter([
  {
    path: "",
    element: <Auth />,
  },
  {
    element: <RootLayout />,
    children: [
      {
        path: "/paxta-yigim/",
        children: [
          {
            path: "hududlar",
            children: [
              {
                index: true,
                element: <RegionPage />,
              },
              {
                path: "region/:id",
                element: <DistrictPage />,
              },
              // {
              //   path: "region/district/:id",
              //   element: <MassivPage />,
              // },
              {
                path: "region/district/territory/:id",
                element: <FermerPage />,
              },
              {
                path: "region/district/territory/provision/:id",
                element: <YukXatiPage />,
              },
            ],
          },
          {
            path: "klasterlar",
            children: [
              {
                index: true,
                element: <KlasterRegionPage />,
              },
              {
                path: "cluster/:id",
                element: <KlasterDistrictInClusterPage />,
                // element: <KlasterDistrictPage />,
              },
              {
                path: "cluster/abode/:id",
                element: <KlasterClusterInAbodePage />,
              },
              {
                path: "cluster/abode/farmers/:id",
                element: <FarmersInPlace />,
              },
              {
                path: "cluster/farmer/consignment-note/:id",
                element: <YukXatiPage />,
              },
            ],
          },
          {
            path: "farmers",
            children: [
              {
                index: true,
                element: <RegionFarmersList />,
              },
              {
                path: "region/:id",
                element: <DistrictFarmersList />,
                // element: <KlasterDistrictPage />,
              },
              {
                path: "region/district/:id",
                element: <FermerPageForPlace />,
              },
              {
                path: "region/district/farmer/consignment-note/:id",
                element: <YukXatiPage />,
              },
            ],
          },
          {
            path: "seed-cotton",
            children: [
              {
                index: true,
                element: <UrugchilikRegionPage />,
              },
              {
                path: "region/district/:id",
                element: <UruglikDistrictPage />,
              },
              {
                path: "region/district/farmers/:id",
                element: <UrugchilikFermerPage />,
              },
              {
                path: "region/district/farmers/yuk-xati/:id",
                element: <UrugchilikYukXatiPage />,
              },
            ],
          },
        ],
      },
      {
        path: "exceldagi",
        children: [
          {
            path: "1-jadval",
            element: <Jadval1 />,
          },
          {
            path: "2-jadval",
            element: <Jadval2 />,
          },
          {
            path: "3-jadval",
            element: <Jadval3 />,
          },
          {
            path: "4-jadval",
            element: <Jadval4 />,
          },
          {
            path: "5-jadval",
            element: <Jadval5 />,
          },
          {
            path: "6-jadval",
            element: <Jadval6 />,
          },
        ],
      },
      {
        path: "download-docs",
        element: <ExcelYuklash />,
      },
      {
        path: "reja-qoshish",
        children: [
          {
            path: "tuman",
            element: <RejaQushishTuman />,
          },
          {
            path: "cluster-plan",
            element: <RejaQushishKlaster />,
          },
        ],
      },
      {
        path: "information",
        children: [
          {
            index: true,
            element: <InformationPage />,
          },
        ],
      },
      {
        path: "cluster-added",
        children: [
          {
            index: true,
            element: <Klaster />,
          },
          {
            path: "add",
            element: <AddCluster />,
          },
          {
            path: "edit/:id",
            element: <AddCluster />,
          },
        ],
      },
      {
        path: "maskanlar",
        children: [
          {
            index: true,
            element: <RegionPlaceList />,
          },
          {
            path: "region/:id",
            element: <Maskan />,
          },
          {
            path: "create",
            element: <AddMaskan />,
          },
          {
            path: "edit/:id",
            element: <AddMaskan />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: "create",
            element: <AddUser />,
          },
          // {
          //   path: "edit/:id",
          //   element: <EditUser />,
          // },
        ],
      },
      {
        path: "dislocations",
        children: [
          {
            index: true,
            element: <RegionDislocationsList />,
          },
          {
            path: "region/:id",
            element: <DistrictDislocationsList />,
          },
          {
            path: "region/district/:id",
            element: <Dislocation />,
          },
          {
            path: "create",
            element: <AddDislocation />,
          },
          {
            path: "edit/:id",
            element: <AddDislocation />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
