import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export function useHooks() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams.entries());
  const contractFilter = (e) => {
    navigate({
      search: new URLSearchParams({
        ...params,
        contract: e,
      }).toString(),
    });
  };
  const SelectFilter = (e) => {
    navigate({
      search: new URLSearchParams({
        ...params,
        unit_type: e,
      }).toString(),
    });
  };
  const DateFilter = (e) => {
    delete params.start_date;
    delete params.end_date;
    navigate({
      search: new URLSearchParams({
        ...params,
        date: e,
      }).toString(),
    });
  };
  const DateRangeFilter = (e) => {
    const searchParams = new URLSearchParams({
      ...params,
    });

    if (e.startDate) {
      searchParams.set("start_date", e.startDate);
    } else {
      searchParams.delete("start_date");
    }

    if (e.endDate) {
      searchParams.set("end_date", e.endDate);
    } else {
      searchParams.delete("end_date");
    }

    navigate({
      search: searchParams.toString(),
    });
  };
  const PkFilter = (e) => {
    navigate({
      search: new URLSearchParams({
        ...params,
        pk17: e,
      }).toString(),
    });
  };
  function dateRangeChange(params) {
    const startDate = params[0] ? dayjs(params[0]).format("YYYY-MM-DD") : null;
    const endDate = params[1] ? dayjs(params[1]).format("YYYY-MM-DD") : null;

    DateRangeFilter({ startDate, endDate });
  }
  return {
    DateFilter,
    DateRangeFilter,
    SelectFilter,
    contractFilter,
    PkFilter,
    dateRangeChange,
  };
}
