import { Controller } from "react-hook-form";
import { FormGroupProvider, MantineTextInput } from "./style";

export const Input = ({ control, defaultValue, ...props }) => {
  return (
    <>
      <Controller
        name={props.name}
        control={control}
        defaultValue={defaultValue ?? null}
        render={({
          field: { onChange, onBlur, value, name, ref },
          formState: { errors },
        }) => (
          <FormGroupProvider>
            <MantineTextInput
              {...props}
              id={name}
              value={value || ""}
              onBlur={onBlur} // notify when input is touched
              onChange={onChange} // send value to hook form
              // checked={value}
              onWheel={(e) => e.target.blur()}
              ref={ref}
              error={errors[props.name] ? errors[props.name]?.message : ""}
            />
          </FormGroupProvider>
        )}
      />
    </>
  );
};
