export default function useHooks() {
  const data = [
    {
      id: 1,
      cluster_name: "Teshaboy MCHJ",
      cutton_area: 12,
      shartnoma: 12,
    },
  ];
  return { data };
}
