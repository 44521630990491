import React from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
// import { Link } from "react-router-dom";
import { TBody } from "styles/style";
// import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

function TableBody({ data, path, storege, deleteMutate }) {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  let decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.access);
  }
  // const navigate = useNavigate();
  // const clickTable = (id, name) => {
  //   if (storege) {
  //     localStorage.setItem(storege, name);
  //   }
  //   // navigate(`${path ? path + id : id}`);
  // };
  return (
    <TBody>
      {data?.length === 0 && (
        <tr>
          <td colSpan={7}>Маълумот йўқ</td>
        </tr>
      )}
      {data?.map((item, index) => (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.company_name ?? "-"}</td>
          <td>{item?.tin ?? "-"}</td>
          <td>{item?.pinfl}</td>
          <td>{item?.region}</td>
          <td>{item?.district ?? "-"}</td>
          <td>{item?.roles}</td>
          {/* <td>
            <Link to={`edit/${item?.id}`}>edit</Link>
          </td> */}
          {decoded?.group === "SUPERADMIN" && (
            <td>
              <RiDeleteBin6Fill
                color="red"
                size={20}
                onClick={() => deleteMutate(item.id)}
              />
            </td>
          )}
        </tr>
      ))}
      {data ? null : (
        <tr>
          <td colSpan={6}>Мавжуд эмас</td>
        </tr>
      )}
    </TBody>
  );
}

export default TableBody;
