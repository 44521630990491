import React from "react";

import {
  FilterWrapper,
  FilterWrapperChild,
  FormGroupProvider,
  MantineSelect,
} from "./style";
import { allColors } from "styles/colors";
// import DatePickerComponent from "components/filter-components/datePickerComponent";
import ModalDownload from "components/modal-download";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import DateRangePicker from "./dateRangePicker";
import { useHooks } from "./useHooks";

const option = [
  {
    label: "Физик",
    value: "physical",
  },
  {
    label: "Кондиция",
    value: "conditioned",
  },
];
const pk17Option = [
  {
    label: "Барчаси",
    value: "all",
  },
  {
    label: "Бор",
    value: "1",
  },
  {
    label: "Йўқ",
    value: "0",
  },
];
const option2 = [
  {
    label: "Барчаси",
    value: "all",
  },
  {
    label: "Фючерс",
    value: "fyuchers",
  },
  {
    label: "Спот",
    value: "spot",
  },
];

function FilterComponent({
  provinsiya = false,
  titleName,
  contract = false,
  pk17 = false,
}) {
  const {
    PkFilter,
    dateRangeChange,
    DateRangeFilter,
    SelectFilter,
    contractFilter,
  } = useHooks();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams.entries());
  const today = dayjs().format("DD.MM.YYYY"); // Bugungi sana
  const datePrint = location.search?.match(/date=([^&]*)/)
    ? location.search?.match(/date=([^&]*)/)[1]
    : today;

  const startDateParam = searchParams.get("start_date");
  const endDateParam = searchParams.get("end_date");
  const initialStartDate = startDateParam
    ? dayjs(startDateParam).toDate()
    : null;
  const initialEndDate = endDateParam ? dayjs(endDateParam).toDate() : null;

  return (
    <FilterWrapper>
      <FilterWrapperChild>
        {/* <DatePickerComponent dateChange={dateChange} /> */}
        {DateRangeFilter && (
          <DateRangePicker
            dateChange={dateRangeChange}
            initialDates={[initialStartDate, initialEndDate]}
          />
        )}
        {!provinsiya && (
          <FormGroupProvider>
            <MantineSelect
              data={option}
              label={"Ўлчов тури"}
              nothingFound="Нет вариантов"
              placeholder={"Танланг"}
              defaultValue="physical"
              onChange={SelectFilter}
              styles={() => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: allColors.mainColor,
                      color: "#fff",
                    },
                  },
                },
              })}
            />
          </FormGroupProvider>
        )}
        {contract && (
          <FormGroupProvider>
            <MantineSelect
              data={option2}
              label={"Шартнома"}
              nothingFound="Нет вариантов"
              placeholder={"Танланг"}
              defaultValue="all"
              onChange={contractFilter}
              styles={() => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: allColors.mainColor,
                      color: "#fff",
                    },
                  },
                },
              })}
            />
          </FormGroupProvider>
        )}

        <FormGroupProvider>
          <MantineSelect
            data={pk17Option}
            label={"ПК-17"}
            nothingFound="Нет вариантов"
            placeholder={"Танланг"}
            defaultValue={params?.pk17 ?? null}
            onChange={PkFilter}
            styles={() => ({
              item: {
                // applies styles to selected item
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: allColors.mainColor,
                    color: "#fff",
                  },
                },
              },
            })}
          />
        </FormGroupProvider>
      </FilterWrapperChild>
      <ModalDownload
        excelParam={{
          fileName: `Фермерлар бўйича_region_${datePrint}`,
          tableId: "withExcel",
          excelTableName: `${titleName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`,
        }}
      />
    </FilterWrapper>
  );
}

export default FilterComponent;
