import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import useSidebarStore from "../../../zustand/useStore";
import OrdinaryTable from "../components/tebles/ordinaryTable";
import { useHooks } from "./useHooks";
import { Button } from "@mantine/core";
import ExcelIcon from "assets/icons/excelIcon.png";

export default function RegionDislocationsList() {
  const year = useSidebarStore((state) => state.selectId);
  const { getRegionsList, isLoading, mutate, getExcelLoading } = useHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          Республика бўйича {year} йил пахта ҳосилини қабул қилиш мониторинги
        </H1>
      </Wrapper>
      <OrdinaryTable
        data={getRegionsList}
        isLoading={isLoading}
        path={"region/"}
        hududName={"Вилоят"}
        storege={"region"}
      />
        <Button
          style={{
            background: "rgb(38 146 94)",
            paddingInline: "8px",
            display: "flex",
            marginTop: "10px",
            marginLeft: "auto",
          }}
          loading={getExcelLoading}
          onClick={() => mutate()}
        >
          <img src={ExcelIcon} alt="excel-icon" />
        </Button>
    </Container>
  );
}
