import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import CustomTable from "./table";
import useHooks from "./useHooks";

export default function Jadval6() {
  const { data } = useHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          Андижон вилояти туманларида 2024 йил пахта ҳосилидан тайёрланган пахта
          хом-ашёсининг ассортименти тўғрисида 5 сентябр холатига тезкор М А Ъ Л
          У М О Т
        </H1>
      </Wrapper>
      <CustomTable isLoading={false} data={data} />
    </Container>
  );
}
