import React from "react";
import { THead, Th } from "styles/style";
import jwt_decode from "jwt-decode";

function TableHeader() {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  let decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.access);
  }
  return (
    <THead>
      <tr>
        <Th>№</Th>
        <Th>Вилоят</Th>
        <Th>Туман</Th>
        <Th>Кластер номи</Th>
        <Th>Маскан номи</Th>
        <Th>Хўжалик номи</Th>
        <Th>СТИР</Th>
        <Th>Оралиқ масофа, км</Th>
        <Th>Режа ялпи хосил, тн</Th>
        <Th>Эдит</Th>
        {decoded?.group === "SUPERADMIN" && <Th>Ўчириш</Th>}
      </tr>
    </THead>
  );
}

export default TableHeader;
