import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import CustomTable from "./table";
import useHooks from "./useHooks";

export default function Jadval5() {
  const { data } = useHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          Андижон вилоятида 2024 йил ҳосилидан пахта териш машиналарида терилган
          пахта хом-ашёси тўғрисида 5 сентябрга бўлган тезкор М А Ъ Л У М О Т
        </H1>
      </Wrapper>
      <CustomTable isLoading={false} data={data} />
    </Container>
  );
}
