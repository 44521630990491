import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>Туманлар номи</Th>
        <Th rowSpan={2}>Шартнома бўйича, тонна</Th>
        <Th colSpan={2}>Бугун</Th>
        <Th colSpan={2}>Кеча</Th>
        <Th colSpan={2}>
          Фарқи, +,- <br />
          кечага нисбатан
        </Th>
        <Th colSpan={2}>Мавсум бошидан</Th>
      </tr>
      <tr>
        <Th>тонна</Th>
        <Th>фоиз</Th>
        <Th>тонна</Th>
        <Th>фоиз</Th>
        <Th>тонна</Th>
        <Th>фоиз</Th>
        <Th>тонна</Th>
        <Th>фоиз</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
