import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import { useHooksTerritory } from "./useHooksTerritory";
import FilterComponent from "components/filter-components";
import FermerTable from "./table";
import useSidebarStore from "../../../zustand/useStore";

function FermerPageForPlace() {
  const {
    getFermerList,
    isLoading,
  } = useHooksTerritory();
  // const territoryName = localStorage.getItem("territory");
  const districtName = localStorage.getItem("district");

  const year = useSidebarStore((state) => state.selectId);
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          {districtName} бўйича {year} йил пахта ҳосилини қабул қилиш
          мониторинги
        </H1>
      </Wrapper>
      <FilterComponent
        titleName={districtName}
        contract
      />
      <FermerTable
        data={getFermerList}
        hududName={"Фермер хўжаликлари"}
        // path={"/paxta-yigim/hududlar/region/district/territory/provision/"}
        isLoading={isLoading}
        storege={"provision"}
        path={"/paxta-yigim/farmers/region/district/farmer/consignment-note/"}
      />
      
    </Container>
  );
}

export default FermerPageForPlace;
