import { Controller } from "react-hook-form";
import { FormGroupProvider, MantineSelect } from "./style";

export const SelectContainer = ({
  control,
  data = [],
  name,
  label,
  placeholder,
  defaultValue,
  disabled = false,
  searchable = true,
  onChange,
  withAsterisk = false,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? null}
        render={({ field, formState: { errors } }) => (
          <FormGroupProvider>
            <MantineSelect
              {...field}
              data={data}
              withAsterisk={withAsterisk}
              label={label || ""}
              searchable={searchable}
              nothingFound="Нет вариантов"
              clearable
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange();
              }}
              placeholder={placeholder || "Танланг"}
              disabled={disabled}
              error={errors[name] ? errors[name]?.message : ""}
            />
          </FormGroupProvider>
        )}
      />
    </>
  );
};
