import { clearUser, getUser } from "utils/user";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_ROOT;

const request = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
request.interceptors.request.use(
  async (config) => {
    const user = getUser();
    if (user?.access) {
      if (user?.isExpiredRefresh) {
        clearUser();
        window.location.href = "/";
        return config;
      }
      // if (user?.isExpiredAccess) {
      config.headers = {
        Authorization: `Bearer ${user?.access}`,
        ...config.headers,
      };
      return config;
      // }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export { request };
