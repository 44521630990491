
export default function addChildTitleForTableExcel (doc, childParent, children) {
  let childNode = null
  let childNodeParent = null
  let childTextNode = null
  if (children) {
    // eslint-disable-next-line array-callback-return
    return children?.map((element) => {
      const childText = element?.childText
      const parent = element?.parent
      childNodeParent = doc.createElement(parent)
      const tag = element?.tag
      const attributes = element?.attributes
      childNode = doc.createElement(tag)
      if (childText) {
        childTextNode = doc.createTextNode(childText)
        childNode.appendChild(childTextNode)
      }
      if (attributes) {
        for (var att in attributes) {
          childNode.setAttribute(att, attributes[att])
        }
      }
      childNodeParent.insertAdjacentElement('beforeend', childNode)
      childParent.insertAdjacentElement('afterbegin', childNodeParent)
    })
  }
  return null
}
