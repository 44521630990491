import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import { Button, Grid } from "@mantine/core";
import { SelectContainer } from "components/select";
import { useHooks } from "./useHooks";
import { Input } from "components/input";
import { ButtonWrapper, Form } from "./style";

function AddUser() {
  const {
    form,
    onSubmit,
    navigate,
    getGroupsList,
    districtOption,
    regionOption,
  } = useHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px 20px" }}
      >
        <H1>Фойдаланувчи қўшиш</H1>

        <Form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{ marginTop: "30px" }}
        >
          <Grid>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="Ташкилот номи"
                name="company_name"
                placeholder="Ташкилот номи"
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="СТИР"
                name="tin"
                placeholder="СТИР"
                type={"number"}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="ПИНФЛ"
                name="pinfl"
                placeholder="ПИНФЛ"
                type={"number"}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Вилоят"
                placeholder="Вилоят"
                name="region"
                data={regionOption ?? []}
                searchable={false}
                required={true}
                onChange={() => form.setValue("district", null)}
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Туман"
                placeholder="Туман"
                name="district"
                data={districtOption ?? []}
                searchable={false}
                required={true}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Роль"
                placeholder="Роль"
                name="roles"
                data={getGroupsList ?? []}
                searchable={false}
                required={true}
                withAsterisk
              />
            </Grid.Col>

            {/* <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="Логин"
                name="login"
                placeholder="Логин"
                // type={"number"}
              />
            </Grid.Col> */}
            {/* <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="Пароль"
                name="password"
                placeholder="Пароль"
              />
            </Grid.Col> */}
            <Grid.Col span={12} lg={12} md={12}>
              <ButtonWrapper>
                <Button
                  type="button"
                  className="cansel"
                  onClick={() => navigate(-1)}
                >
                  Ortga qaytish
                </Button>
                <Button type="submit" className="success">
                  Сақлаш
                </Button>
              </ButtonWrapper>
            </Grid.Col>
          </Grid>
        </Form>
      </Wrapper>
    </Container>
  );
}

export default AddUser;
