import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>Ҳудудлар</Th>
        <Th rowSpan={2}>
          Шартнома бўйича, <br />
          тонна
        </Th>
        <Th colSpan={2}>Бир кунда</Th>
        <Th colSpan={2}>Мавсум бошидан</Th>
        <Th colSpan={3}>Пахта териш машиналари</Th>
      </tr>
      <tr>
        <Th>тонна</Th>
        <Th>%</Th>
        <Th>тонна</Th>
        <Th>%</Th>
        <Th>
          Мавжуд, <br />
          дона
        </Th>
        <Th>
          Ишлади, <br />
          дона
        </Th>
        <Th>
          Ўртача 1 та машинада <br />
          терилган пахта, тонна
        </Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
