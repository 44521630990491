import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getAllRequest } from "shared/modules/getAllRequest";
export function useHooks() {
  const navigate = useNavigate();
  const { data: getRegionsList = [], isLoading } = useQuery(
    ["get_dislocation-regions_list-added"],
    () => getAllRequest(`/tools/dislocation/regions/list/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { mutate, isLoading: getExcelLoading } = useMutation(
    () => getAllRequest(`/tools/dislocations/excel/`),
    {
      onSuccess: (res) => {
        window.open(
          process.env.REACT_APP_API_ROOT + "/" + res?.data?.path,
          "_blank"
        );
      },
      onError: () => {
        notifications.show({
          message: "Хатолик юз берди.",
          color: "red",
        });
      },
    }
  );
  return {
    getRegionsList,
    isLoading,
    getExcelLoading,
    mutate,
  };
}
