import React from "react";
import { TBody } from "styles/style";
// import { TFoot, TableCol } from "../style";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function TableBody({ data, path, storege }) {
  const navigate = useNavigate();
  const location = useLocation();
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  // const clickTable = (id, name) => {
  //   if (storege) {
  //     localStorage.setItem(storege, name);
  //   }
  //   navigate(`${path ? path + id : id}`);
  // };

  return (
    <>
      <TBody>
        <tr>
          <td>1</td>
          <td colSpan={10}>Andijon v</td>
        </tr>
        {data?.map((item, index) => (
          <tr
            key={item.object_id}
            // onClick={() => clickTable(item.object_id, item.name)}
          >
            <td>{index + 1}</td>
            <td>{item.sector}</td>
            {/* <td>{item.cluster_name}</td> */}
            <td>{item.cutton_area}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
            <td>{item.shartnoma}</td>
          </tr>
        ))}
        <tr></tr>
      </TBody>
      {/* <TFoot>
        
      </TFoot> */}
    </>
  );
}

export default TableBody;
