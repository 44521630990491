export default function useHooks() {
  const data = [
    {
      id: 1,
      sector: 'I-sektor',
      cluster_name: "Teshaboy1 MCHJ",
      cutton_area: 12,
      shartnoma: 12,
    },
    {
      id: 2,
      sector: 'II-sektor',
      cluster_name: "Teshaboy2 MCHJ",
      cutton_area: 122,
      shartnoma: 12,
    },
    {
      id: 3,
      sector: 'III-sektor',
      cluster_name: "Teshaboy3 MCHJ",
      cutton_area: 123,
      shartnoma: 12,
    },
    {
      id: 4,
      sector: 'IV-sektor',
      cluster_name: "Teshaboy4 MCHJ",
      cutton_area: 124,
      shartnoma: 12,
    },
  ];
  return { data };
}
