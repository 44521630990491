import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import CustomTable from "./table";
import useHooks from "./useHooks";

export default function Jadval4() {
  const { data } = useHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          Андижон вилоятида 2024 йил ҳосилидан пахта хом-ашёси тайёрлаш
          тўғрисида 5 сентябрга бўлган тезкор
          <br />
          МАЪЛУМОТ
        </H1>
      </Wrapper>
        <CustomTable isLoading={false} data={data} />
    </Container>
  );
}
