import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import FilterComponent from "components/filter-components";
import LocaleTableKlasterFarmers from "../farmersTable";
import useSidebarStore from "../../../../zustand/useStore";
import { useHooks } from "./useHooks";

function FarmersInPlace() {
  const {
    getFarmersList,
    isLoading,
  } = useHooks();
  const clusterName = localStorage.getItem("place");
  const year = useSidebarStore((state) => state.selectId);

  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          {clusterName} маскан бўйича {year} йил пахта ҳосилини қабул қилиш
          мониторинги
        </H1>
      </Wrapper>
      <FilterComponent
        
        titleName={clusterName}
        provinsiya
      />
      <LocaleTableKlasterFarmers
        clusterName={clusterName}
        data={getFarmersList}
        isLoading={isLoading}
        hududName={"Пахта тозалаш корхоналари"}
        path={"/paxta-yigim/klasterlar/cluster/farmer/consignment-note/"}
        storege={"provision"}
      />
    </Container>
  );
}

export default FarmersInPlace;
