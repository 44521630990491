import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={3}>№</Th>
        <Th rowSpan={3}>Ҳудудлар</Th>
        <Th rowSpan={3}>Кластерлар номи</Th>
        <Th rowSpan={3}>Шартнома режа</Th>
        <Th colSpan={18}>Шу жумладан навлар бўйича</Th>
      </tr>
      <tr>
        <Th colSpan={4}>I-нав</Th>
        <Th colSpan={4}>II-нав</Th>
        <Th colSpan={4}>III-нав</Th>
        <Th colSpan={4}>IV-нав</Th>
        <Th colSpan={2}>V-нав</Th>
      </tr>
      <tr>
        <Th>Жами</Th>
        <Th>1 - синф</Th>
        <Th>2 - синф</Th>
        <Th>3 - синф</Th>
        <Th>Жами</Th>
        <Th>1 - синф</Th>
        <Th>2 - синф</Th>
        <Th>3 - синф</Th>
        <Th>Жами</Th>
        <Th>1 - синф</Th>
        <Th>2 - синф</Th>
        <Th>3 - синф</Th>
        <Th>Жами</Th>
        <Th>1 - синф</Th>
        <Th>2 - синф</Th>
        <Th>3 - синф</Th>
        <Th>Жами</Th>
        <Th>3-синф</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
