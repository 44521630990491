import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import { useHooksProvision } from "./useHooksProvision";
import GlobalTableYukXati from "components/yuk-xati-table";
import FilterComponent from "components/filter-components";
import useSidebarStore from "../../../zustand/useStore";

function UrugchilikYukXatiPage() {
  const {
    getCottonList,
    isLoading,
  } = useHooksProvision();
  const farmersName = localStorage.getItem("farmersName");
  const year = useSidebarStore((state) => state.selectId);
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          {farmersName} бўйича {year} йил уруғлик пахта ҳосилини қабул қилиш
          мониторинги
        </H1>
      </Wrapper>
      <FilterComponent
        provinsiya={true}
        titleName={farmersName}
        pk17
      />
      <GlobalTableYukXati data={getCottonList} isLoading={isLoading} />
    </Container>
  );
}

export default UrugchilikYukXatiPage;
