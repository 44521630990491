import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>Ҳудудлар</Th>
        <Th rowSpan={2}>
          Режадаги жами теримчилар, <br />
          сони
        </Th>
        <Th colSpan={3}>Амалда қатнашди</Th>
        <Th colSpan={2}>Фарқи, +,-</Th>
      </tr>
      <tr>
        <Th>Кеча</Th>
        <Th>Бугун</Th>
        <Th>Ўртача 1 та теримчи <br/> терган пахта, кг</Th>
        <Th>Режага нисбатан</Th>
        <Th>Кечага нисбатан</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
