import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import Tables from "./components/tebles";
import { Button } from "@mantine/core";
import { ButtonContainer } from "./style";
import { useNavigate } from "react-router-dom";
import { useUsersListHooks } from "./useHooksUsers";

function Users() {
  const navigate = useNavigate();
  const { getUsersList, isLoading, deleteMutate } = useUsersListHooks();
  return (
    <Container
      style={{
        marginBottom: "30px",
      }}
    >
      <Wrapper
        style={{
          marginTop: "30px",
          background: "#fff",
          padding: "10px 20px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <H1>Фойдаланувчилар рўйхати</H1>
        <ButtonContainer>
          <Button className="button" onClick={() => navigate("create")}>
            Фойдаланувчи қўшиш
          </Button>
        </ButtonContainer>
      </Wrapper>
      <Tables
        data={getUsersList}
        isLoading={isLoading}
        deleteMutate={deleteMutate}
      />
    </Container>
  );
}

export default Users;
