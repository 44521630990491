import styled from "@emotion/styled";
import { Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

import { allColors } from "styles/colors";
export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
  align-items: end;
`;
export const FilterWrapperChild = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const FormGroupProvider = styled("div")`
  width: 220px;
  & .mantine-PasswordInput-innerInput {
    height: auto;
  }
  & .mantine-Input-wrapper {
    height: auto;
  }
`;

export const MantineSelect = styled(Select)`
  width: 100%;
  input {
    width: 100%;
    height: 38px;
    border: 0.8px solid #cbd1d8;
    font-family: "Inter", sans-serif;
    background-color: #cbd1d8;
    border-radius: 6px;
    font-feature-settings: "tnum" on, "lnum" on !important;
    &::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      /* color: #cbd1d8; */
    }
    &:focus {
      border: 0.8px solid #cbd1d8;
    }
  }
  label {
    margin: 5px 0;
    font-family: "Inter", sans-serif;
    color: #60676d;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const MantineDatePicker = styled(DatePickerInput)`
  width: 100%;
  min-width: 100%;
  .mantine-DatePickerInput-input {
    width: 100%;
    height: 38px;
    background-color: #cbd1d8;
    border: 0.8px solid #cbd1d8;
    font-family: "Inter", sans-serif;
    border-radius: 6px;
    font-feature-settings: "tnum" on, "lnum" on !important;
    &::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #cbd1d8;
    }
  }
  .mantine-DatePickerInput-day[data-selected] {
    background-color: ${allColors.mainColor};
  }
  label {
    margin: 5px 0;
    font-family: "Inter", sans-serif;
    color: #60676d;
    font-size: 16px;
    font-weight: 500;
  }
`;
export const MantineDateRangePicker = styled(DatePickerInput)`
  width: 100%;
  min-width: 100%;
  .mantine-DatePickerInput-input {
    width: 100%;
    height: 38px;
    background-color: #cbd1d8;
    border: 0.8px solid #cbd1d8;
    font-family: "Inter", sans-serif;
    border-radius: 6px;
    font-feature-settings: "tnum" on, "lnum" on !important;
    &::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #cbd1d8;
    }
  }
  .mantine-DatePickerInput-day[data-selected] {
    background-color: ${allColors.mainColor};
  }
  label {
    margin: 5px 0;
    font-family: "Inter", sans-serif;
    color: #60676d;
    font-size: 16px;
    font-weight: 500;
  }
`;
