import React from "react";
import { THead, Th } from "styles/style";
import jwt_decode from "jwt-decode";

function TableHeader() {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  let decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.access);
  }
  return (
    <THead>
      <tr>
        <Th>№</Th>
        <Th>Ташкилот номи</Th>
        <Th>Ташкилот стир</Th>
        <Th>ПИНФЛ</Th>
        <Th>Вилоят</Th>
        <Th>Tuman</Th>
        <Th>Роль</Th>
        {/* <Th>Маскан номи</Th> */}
        {/* <Th>Эдит</Th> */}
        {decoded?.group === "SUPERADMIN" && <Th>Ўчириш</Th>}
      </tr>
    </THead>
  );
}

export default TableHeader;
