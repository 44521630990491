import React from "react";
// import { Link } from "react-router-dom";
import { TBody } from "styles/style";
import { useNavigate } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import { TableCol, TFoot } from "../style";

function TableBody({ data, path, storege, deleteMutate }) {
  // const userInfo = JSON.parse(localStorage.getItem("user"));
  // let decoded = "";
  // if (userInfo) {
  //   decoded = jwt_decode(userInfo.access);
  // }
  const navigate = useNavigate();
  const clickTable = (id, name) => {
    if (storege) {
      localStorage.setItem(storege, name);
    }
    navigate(`${path ? path + id : id}`);
  };
  return (
    <>
      <TBody>
        {data?.length === 0 && (
          <tr>
            <td colSpan={14}>Маълумот йўқ</td>
          </tr>
        )}
        {data?.map((item, index) => (
          <tr key={item.id} onClick={() => clickTable(item?.id, item.name)}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.contract_count}</td>
            <td>{item.crop_plan}</td>
            <td>{item.count_50}</td>
            <td>{item.left_50}</td>
            <td>{item.count_70}</td>
            <td>{item.left_70}</td>
            <td>{item.count_90}</td>
            <td>{item.left_90}</td>
            <td>{item.count_100}</td>
            <td>{item.left_100}</td>
            <td>{item.count_done}</td>
            <td>{item.left_done}</td>
          </tr>
        ))}
      </TBody>
      <TFoot>
        <tr>
          <TableCol colSpan={2}>Жами:</TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.contract_count, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.crop_plan, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.count_50, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.left_50, 0).toFixed(2)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.count_70, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.left_70, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.count_90, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.left_90, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.count_100, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.left_100, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.count_done, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.left_done, 0).toFixed(2)}
          </TableCol>
        </tr>
      </TFoot>
    </>
  );
}

export default TableBody;
