export const allColors = {
  mainColor: "rgb(38 146 94)",
  shriftColor: "#313131",
  shriftColorOpasity3: "#60676D",
  whiteColor: "#ffffff",
  selectBgColor: "#F5F5F5",
  shriftRed: "#EB5757",
  seriyColor: "#E7E7E7",
  colorBlue: "#2E7DF6",
};
