import React from "react";
import { TBody } from "styles/style";
import { TFoot, TableCol } from "../style";
import { useNavigate } from "react-router-dom";

function TableBody({ data, path, storege, clusterName }) {
  const navigate = useNavigate();
  const clickTable = (id, name) => {
    if (storege) {
      localStorage.setItem(storege, name);
    }
    if (storege !== "cluster") {
      navigate(`${path ? path + id : id}`);
    }
  };
  return (
    <>
      <TBody>
        {data?.data?.map((item, index) => (
          <tr
            key={item.object_id ?? index}
            onClick={() => clickTable(item.farmer_tax_number, item.name)}
          >
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.farmer_tax_number}</td>
            <td>{item.crop_plan}</td>
            <td>{item.physical_daily_net}</td>
            <td>{item.conditioned_daily_net}</td>
            <td>{item.daily_discount}</td>
            <td>{item.daily_cape}</td>
            <td>{item.physical_season_net}</td>
            <td>{item.conditioned_season_net}</td>
            <td>{item.season_discount}</td>
            <td>{item.season_cape}</td>
            <td>{item.interruption || "-"}</td>
          </tr>
        ))}
        <tr></tr>
      </TBody>
      <TFoot>
        <tr>
          <TableCol colSpan={2}>Жами:</TableCol>
          <TableCol></TableCol>
          <TableCol>{data?.totals?.total_crop_plan}</TableCol>
          <TableCol>{data?.totals?.total_physical_daily_net}</TableCol>
          <TableCol>{data?.totals?.total_conditioned_daily_net}</TableCol>
          <TableCol>{data?.totals?.total_daily_discount}</TableCol>
          <TableCol>{data?.totals?.total_daily_cape}</TableCol>
          <TableCol>{data?.totals?.total_physical_season_net}</TableCol>
          <TableCol>{data?.totals?.total_conditioned_season_net}</TableCol>
          <TableCol>{data?.totals?.total_season_discount}</TableCol>
          <TableCol>{data?.totals?.total_season_cape}</TableCol>
          <TableCol>{"-"}</TableCol>
        </tr>
      </TFoot>
    </>
  );
}

export default TableBody;
