import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import { Button, Grid } from "@mantine/core";
import { SelectContainer } from "components/select";
import { useAddPlanClusterHooks } from "./useHooks";
import { Input } from "components/input";
import { ButtonWrapper, Form } from "./style";

function AddDislocation() {
  const {
    form,
    regionOption,
    regionId,
    onSubmit,
    districtId,
    getDistrict,
    id,
    navigate,
    getClusterList,
    getPlaceList,
    getXujalikList,
    decoded,
    isLoading,
  } = useAddPlanClusterHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px 20px" }}
      >
        <H1>Дислокацияни {id ? "тахрирлаш" : "қўшиш"}</H1>

        <Form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{ marginTop: "30px" }}
        >
          <Grid>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Вилоят"
                placeholder="Танланг"
                name="region"
                data={regionOption ?? []}
                disabled={decoded?.group === "PLACE_ADMIN" ? true : false}
                required={true}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Туман"
                placeholder="Танланг"
                name="district"
                data={getDistrict ?? []}
                searchable={false}
                disabled={regionId ? false : true}
                required={true}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Кластер"
                placeholder="Танланг"
                name="cluster_tax_number"
                data={getClusterList ?? []}
                searchable={false}
                disabled={districtId ? false : true}
                required={true}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Маскан номи"
                placeholder="Танланг"
                name="place"
                data={getPlaceList ?? []}
                searchable={false}
                disabled={form.watch("cluster_tax_number") ? false : true}
                required={true}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Хўжалик"
                placeholder="Танланг"
                name="farmer_tax_number"
                data={getXujalikList ?? []}
                // searchable={false}
                disabled={form.watch("cluster_tax_number") ? false : true}
                required={true}
              />
              {/* <MultiSelectContainer
                control={form.control}
                label="Хўжалик"
                placeholder="Танланг"
                name="farmer_tax_number"
                data={getXujalikList ?? []}
                // searchable={false}
                disabled={form.watch("cluster_tax_number") ? false : true}
                required={true}
              /> */}
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="Оралиқ масофа, км"
                name="distance"
                placeholder="Киритинг"
                type={"number"}
                disabled={form.watch("farmer_tax_number") ? false : true}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <Input
                control={form.control}
                label="Режа ялпи хосил, тн"
                name="farmer_plan"
                placeholder="Киритинг"
                type={"number"}
                disabled={true}
                // disabled={form.watch("farmer_tax_number") ? false : true
              />
            </Grid.Col>
            <Grid.Col span={12} lg={12} md={12}>
              <ButtonWrapper>
                <Button
                  type="button"
                  className="cansel"
                  onClick={() => navigate(-1)}
                >
                  Ortga qaytish
                </Button>
                <Button type="submit" className="success" loading={isLoading}>
                  Сақлаш
                </Button>
              </ButtonWrapper>
            </Grid.Col>
          </Grid>
        </Form>
      </Wrapper>
    </Container>
  );
}

export default AddDislocation;
