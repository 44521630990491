import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>Туманлар номи</Th>
        <Th rowSpan={2}>Кластерлар номи</Th>
        <Th rowSpan={2}>
          Пахта майдони,
          <br /> минг га
        </Th>
        <Th rowSpan={2}>
          Шартнома, <br /> тонна
        </Th>
        <Th colSpan={2}>Бир кунда</Th>
        <Th colSpan={2}>Мавсум бошидан</Th>
        <Th colSpan={2}>
          Шундан қул терими, <br />
          тонна
        </Th>
        <Th colSpan={2}>
          Шундан машина терими, <br />
          тонна
        </Th>
        <Th colSpan={2}>
          Ҳосилдорлик,
          <br /> ц/га
        </Th>
      </tr>
      <tr>
        <Th>тонна</Th>
        <Th>%</Th>
        <Th>тонна</Th>
        <Th>%</Th>
        <Th>бир кунда</Th>
        <Th>мавсум бошидан</Th>
        <Th>бир кунда</Th>
        <Th>мавсум бошидан</Th>
        <Th>шартнома</Th>
        <Th>амалда</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
