import { useMutation, useQuery } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { getAllRequest } from "shared/modules/getAllRequest";
import { useForm, useWatch } from "react-hook-form";
import { postAllRequest } from "shared/modules/postAllRequiest";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./form.schema";
import { useNavigate, useParams } from "react-router-dom";
import { patchAllRequest } from "shared/modules/patchAllRequest";
import jwt_decode from "jwt-decode";

export function useAddPlanClusterHooks() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  let decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.access);
  }
  const form = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      region: decoded.group === "PLACE_ADMIN" ? decoded.object_id : "",
    },
  });
  const regionId = useWatch({
    control: form.control,
    name: "region",
  });
  const districtId = useWatch({
    control: form.control,
    name: "district",
  });

  const { mutate, isLoading } = useMutation(
    (data1) =>
      id
        ? patchAllRequest(`/tools/dislocation/${id}/`, data1)
        : postAllRequest("/tools/dislocation/", data1),
    {
      onSuccess: (res) => {
        form.reset();
        // console.log(res, "resss");
        notifications.show({
          title: `Дислокация ${id ? "тахрирлаш" : "қўшиш"}`,
          message: `Муваффақиятли ${id ? "тахрирлаш" : "қўшилди!"}`,
          color: "teal",
        });
        navigate(-1);
      },
      onError: (err) => {
        // console.log(err, "errrt");
        notifications.show({
          title: `Дислокация ${id ? "тахрирлаш" : "қўшиш"}`,
          message: err?.response?.data?.detail || "Хатолик юз берди!!!",
          color: "red",
        });
        if (err?.response?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: regionOption = [] } = useQuery(
    ["get_region_add_dislocation"],
    () => getAllRequest(`/tools/regions/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: getDistrict = [] } = useQuery(
    ["get_district_add_dislocation", regionId],
    () => getAllRequest(`/tools/districts/${regionId}/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: regionId ? true : false,
    }
  );
  const { data: getClusterList = [] } = useQuery(
    ["get_claster_add_dislocation", districtId],
    () => getAllRequest(`/tools/clusters/dislocation/${regionId}/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: districtId ? true : false,
    }
  );
  const { data: getPlaceList = [] } = useQuery(
    ["get_maskan_add_dislocation", form.watch("cluster_tax_number")],
    () => getAllRequest(`/tools/places/${form.watch("cluster_tax_number")}/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: form.watch("cluster_tax_number") ? true : false,
    }
  );
  const { data: getXujalikList = [] } = useQuery(
    ["get_xujalik_add_dislocation", form.watch("cluster_tax_number")],
    () =>
      getAllRequest(`/tools/farmers/`, {
        cluster: form.watch("cluster_tax_number"),
        district: form.watch("district"),
        exclude: id ? 0 : 1,
      }),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: form.watch("cluster_tax_number") ? true : false,
    }
  );
  useQuery(
    ["get_edit_dislocation"],
    () => getAllRequest(`/tools/dislocation/${id}/`),
    {
      select: (res) => {
        return res.data;
      },
      onSuccess: (res) => {
        form.reset({
          ...res,
          place: String(res.place),
          // farmers: res?.farmers.map((item) => item.value),
        });
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: id ? true : false,
    }
  );
  const onSubmit = (data) => {
    const payload = {
      ...data,
      farmer_plan: null,
    };
    mutate(payload);
  };

  return {
    form,
    regionOption,
    regionId,
    onSubmit,
    districtId,
    getDistrict,
    id,
    navigate,
    getClusterList,
    getXujalikList,
    getPlaceList,
    decoded,
    isLoading,
  };
}
