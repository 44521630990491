import { allColors } from "styles/colors";
import styled from "@emotion/styled";

export const Form = styled.form`
  width: 100%;
  box-sizing: border-box;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  flex-wrap: wrap;
  .cansel {
    background-color: ${allColors.whiteColor};
    border: 1px solid ${allColors.shriftRed};
    color: ${allColors.shriftRed};
  }
  .success {
    background-color: ${allColors.mainColor};
    border: 1px solid ${allColors.mainColor};
    color: ${allColors.whiteColor};
    width: 130px;
  }
`;
