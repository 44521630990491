import React from "react";
import { TBody } from "styles/style";
import { TFoot, TableCol } from "../style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function TableBody({ data, path, storege }) {
  const location = useLocation();
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const navigate = useNavigate();
  const district_id = useParams();
  const clickTable = (id, name) => {
    if (storege) {
      localStorage.setItem(storege, name);
    }
    navigate(`${path ? path + id : id}?district_id=${district_id?.id}`);
  };
  return (
    <>
      <TBody>
        {data?.data?.map((item, index) => (
          <tr
            key={item.object_id}
            onClick={() => clickTable(item.object_id, item.name)}
          >
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.inn ?? "-"}</td>
            <td>
              {
                !params?.contract || params?.contract === "all"
                  ? Number(item?.plan)?.toLocaleString()
                  : params?.contract === "fyuchers"
                  ? Number(item.crop_plan)?.toLocaleString()
                  : "-"
                // Number(
                //   (+item?.plan - +item.crop_plan).toFixed(2)
                // )?.toLocaleString()
              }
            </td>
            {/* <td>{item.crop_plan || 0}</td> */}
            <td>
              {params?.unit_type === "conditioned"
                ? item?.conditioned_daily_net
                : item?.physical_daily_net}
            </td>
            <td>
              {params?.contract === "fyuchers"
                ? params?.unit_type === "conditioned"
                  ? item?.crop_conditioned_daily_net_percent
                  : item?.crop_physical_daily_net_percent
                : params?.contract === "spot"
                ? "-"
                : params?.unit_type === "conditioned"
                ? item?.conditioned_daily_net_percent
                : item?.physical_daily_net_percent}
            </td>
            <td>
              {params?.unit_type === "conditioned"
                ? item?.conditioned_season_net
                : item?.physical_season_net}
            </td>
            <td>
              {params?.contract === "fyuchers"
                ? params?.unit_type === "conditioned"
                  ? item?.crop_conditioned_season_net_percent
                  : item?.crop_physical_season_net_percent
                : params?.contract === "spot"
                ? "-"
                : params?.unit_type === "conditioned"
                ? item?.conditioned_season_net_percent
                : item?.physical_season_net_percent}
            </td>
            {/* <td>{item.crop_remainder || 0}</td> */}
            <td>
              {params?.contract === "fyuchers"
                ? params?.unit_type === "conditioned"
                  ? item?.conditioned_crop_remainder
                  : item?.physical_crop_remainder
                : params?.contract === "spot"
                ? "-"
                : // params?.unit_type === "conditioned"
                //   ? (
                //       +item?.conditioned_crop_remainder +
                //       +item.conditioned_remainder
                //     ).toFixed(2)
                //   : (
                //       +item?.physical_crop_remainder + +item.physical_remainder
                //     ).toFixed(2)
                params?.unit_type === "conditioned"
                ? item?.conditioned_remainder
                : item?.physical_remainder}
            </td>
            <td>{item.cluster_name ?? "-"}</td>
            <td>{item.cluster_inn ?? "-"}</td>
            <td>{item.interruption}</td>
          </tr>
        ))}
        <tr></tr>
      </TBody>
      <TFoot>
        <tr>
          <TableCol colSpan={2}>Жами:</TableCol>
          <TableCol></TableCol>
          <TableCol>
            {!params?.contract || params?.contract === "all"
              ? Number(data?.totals?.total_plan)?.toLocaleString()
              : params?.contract === "fyuchers"
              ? Number(data?.totals?.total_crop_plan)?.toLocaleString()
              : "-"}
          </TableCol>
          {/* <TableCol>{data?.totals?.total_crop_plan ?? 0}</TableCol> */}

          <TableCol>
            {" "}
            {params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_daily_net
              : data?.totals?.total_physical_daily_net}
          </TableCol>
          <TableCol>
            {params?.contract === "fyuchers"
              ? params?.unit_type === "conditioned"
                ? data?.totals?.total_crop_conditioned_daily_net_percent
                : data?.totals?.total_crop_physical_daily_net_percent
              : params?.contract === "spot"
              ? "-"
              : params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_daily_net_percent
              : data?.totals?.total_physical_daily_net_percent}
            {/* {params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_daily_net_percent
              : data?.totals?.total_physical_daily_net_percent} */}
          </TableCol>
          <TableCol>
            {params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_season_net
              : data?.totals?.total_physical_season_net}
          </TableCol>
          <TableCol>
            {params?.contract === "fyuchers"
              ? params?.unit_type === "conditioned"
                ? data?.totals?.total_crop_conditioned_season_net_percent
                : data?.totals?.total_crop_physical_season_net_percent
              : params?.contract === "spot"
              ? "-"
              : params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_season_net_percent
              : data?.totals?.total_physical_season_net_percent}
            {/* {params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_season_net_percent
              : data?.totals?.total_physical_season_net_percent} */}
          </TableCol>
          {/* <TableCol>{data?.totals?.total_crop_remainder ?? 0}</TableCol> */}
          <TableCol>
            {" "}
            {params?.contract === "fyuchers"
              ? params?.unit_type === "conditioned"
                ? data?.totals?.total_conditioned_crop_remainder
                : data?.totals?.total_physical_crop_remainder
              : params?.contract === "spot"
              ? "-"
              : // params?.unit_type === "conditioned"
              //   ?
              //   (
              //     +data?.totals?.total_conditioned_crop_remainder +
              //     +data?.totals.total_conditioned_remainder
              //   ).toFixed(2)
              // : (
              //     +data?.totals?.total_physical_crop_remainder +
              //     +data?.totals?.total_physical_remainder
              //   ).toFixed(2)

              params?.unit_type === "conditioned"
              ? data?.totals?.total_conditioned_remainder
              : data?.totals?.total_physical_remainder}
          </TableCol>
          <TableCol></TableCol>
          <TableCol></TableCol>
          <TableCol></TableCol>
        </tr>
      </TFoot>
    </>
  );
}

export default TableBody;
