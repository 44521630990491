import { useQuery } from "@tanstack/react-query";
import { getAllRequest } from "shared/modules/getAllRequest";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import useSidebarStore from "../../../../zustand/useStore";

export function useHooks() {
  const navigate = useNavigate();
  const location = useLocation();
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  const { id } = useParams();
  const year = useSidebarStore((state) => state.selectId);

  const { data: getFarmersList = [], isLoading: loadingFarmer } = useQuery(
    ["get_place_farmer_list", year,params.end_date,params?.pk17],
    () =>
      getAllRequest(`/api/by-place/farmers/${id}/`, {
        year: year,
        unit_type: params.unit_type ?? undefined,
        start_date: params.start_date ?? undefined,
        end_date: params.end_date ?? undefined,
        pk17: params?.pk17 ?? undefined,
      }),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const SelectFilter = (e) => {
    navigate({
      search: new URLSearchParams({
        ...params,
        unit_type: e,
      }).toString(),
    });
  };
  const DateFilter = (e) => {
    delete params.start_date;
    delete params.end_date;
    navigate({
      search: new URLSearchParams({
        ...params,
        date: e,
      }).toString(),
    });
  };
  const DateRangeFilter = (e) => {
    const searchParams = new URLSearchParams({
      ...params,
    });

    if (e.startDate) {
      searchParams.set("start_date", e.startDate);
    } else {
      searchParams.delete("start_date");
    }

    if (e.endDate) {
      searchParams.set("end_date", e.endDate);
    } else {
      searchParams.delete("end_date");
    }

    navigate({
      search: searchParams.toString(),
    });
  };
  return {
    SelectFilter,
    isLoading: loadingFarmer,
    DateFilter,
    getFarmersList,
    DateRangeFilter,
  };
}
