import styled from "@emotion/styled";
import { allColors } from "styles/colors";

export const ButtonContainer= styled('div')`
display: flex;
align-items: center;
justify-content: space-between;
.button{
    min-width: 142px;
    height: 38px;
    background: none;
    border: 1px solid ${allColors.mainColor};
    border-radius: 8px;
    color: ${allColors.mainColor}
}
`