import { useQuery } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { getAllRequest } from "shared/modules/getAllRequest";
import useSidebarStore from "../../../zustand/useStore";

export function useHooksHudud() {
  const navigate = useNavigate();
  const location = useLocation();
  const year = useSidebarStore((state) => state.selectId);
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());

  const { data: getRegionList = null, isLoading } = useQuery(
    ["get_region_list_urug", year, params.end_date,params?.pk17],
    () =>
      getAllRequest(`/api/region-seed/`, {
        year: year,
        // unit_type: params.unit_type ?? undefined,
        start_date: params.start_date ?? undefined,
        end_date: params.end_date ?? undefined,
        pk17: params?.pk17 ?? undefined,
      }),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );

  return {
    getRegionList,
    isLoading,
  };
}
