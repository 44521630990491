import React from "react";
import { Table, TableContainer } from "styles/style";
import TableHeader from "./table-header";
import TableBody from "./table-body";
import ReactLoader from "components/react-loader";

function FermerTable({ data, hududName, path, isLoading,storege }) {
  if (isLoading) return <ReactLoader isLoading={isLoading} />;
  else
    return (
      <TableContainer style={{ marginTop: "20px" }}>
        <Table id="withExcel">
          <TableHeader hududName={hududName} />
          <TableBody data={data} path={path} storege={storege} />
        </Table>
      </TableContainer>
    );
}

export default FermerTable;
