import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import useSidebarStore from "../../../zustand/useStore";
import { useHooks } from "./useHooks";
import Tables from "../tables/province-table";
import FilterComponent from "components/filter-components";

export default function DistrictFarmersList() {
  const year = useSidebarStore((state) => state.selectId);
  const {
    getDistrictList,
    isLoading,
  } = useHooks();
  const regionName = localStorage.getItem("region");
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          {regionName} бўйича {year} йил пахта ҳосили етиштирувчи ф/х сони ва
          улар зиммасидаги қолдиқ
        </H1>
      </Wrapper>
      <FilterComponent
        titleName={"Tuman"}
        provinsiya
      />
      <Tables
        data={getDistrictList}
        isLoading={isLoading}
        path={"/paxta-yigim/farmers/region/district/"}
        hududName={"Вилоят"}
        storege={"district"}
      />
    </Container>
  );
}
