import React from "react";
import { ThreeCircles } from "react-loader-spinner";

function ReactLoader({ isLoading = false }) {
  return (
    <ThreeCircles
      height="80"
      width="80"
      radius={1}
      color="rgba(34, 106, 71, 1)"
      ariaLabel="three-circles-rotating"
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
        margin: "10px 0",
      }}
      wrapperClass=""
      visible={isLoading}
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
  );
}

export default ReactLoader;
