import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import Tables from "../components/tebles/dislocation";
import { Button } from "@mantine/core";
import { ButtonContainer } from "./style";
import { useNavigate } from "react-router-dom";
import { useDislocationListHooks } from "./useHooksDislocation";

function Dislocation() {
  const navigate = useNavigate();
  const { getDislocationList, isLoading, deleteMutate } =
    useDislocationListHooks();
  const districtName = localStorage.getItem("district");

  return (
    <Container style={{marginBottom:'30px'}}>
      <Wrapper
        style={{
          marginTop: "30px",
          background: "#fff",
          padding: "10px 20px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <H1>{districtName} дислокациялар рўйхати</H1>
        <ButtonContainer>
          <Button className="button" onClick={() => navigate("/dislocations/create")}>
            Дислокация қўшиш
          </Button>
        </ButtonContainer>
      </Wrapper>
      <Tables data={getDislocationList} isLoading={isLoading} deleteMutate={deleteMutate}/>
    </Container>
  );
}

export default Dislocation;
