import { useQuery } from "@tanstack/react-query";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { getAllRequest } from "shared/modules/getAllRequest";
import useSidebarStore from "../../../zustand/useStore";

export function useHooks() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const year = useSidebarStore((state) => state.selectId);
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());

  const { data: getDistrictList = [], isLoading } = useQuery(
    [
      "get_district_farmers_list",
      params.unit_type,
      year,
      params.end_date,
      params?.pk17,
    ],
    () =>
      getAllRequest(`/api/farmer/districts/${id}/`, {
        year: year,
        unit_type: params.unit_type ?? undefined,
        start_date: params.start_date ?? undefined,
        end_date: params.end_date ?? undefined,
        pk17: params?.pk17 ?? undefined,
      }),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );

 
 
 
  return {
    getDistrictList,
    isLoading,
  };
}
