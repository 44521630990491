import * as yup from "yup";

export const formSchema = yup.object().shape({
  company_name: yup.string().required("Илтимос майдонни тўдиринг!"),
  tin: yup.string().min(9).max(9).required("Илтимос майдонни тўдиринг!"),
  roles: yup.string().required("Илтимос майдонни тўдиринг!"),
  pinfl: yup.string().min(14).max(14).required("Илтимос майдонни тўдиринг!"),
  region: yup.string().required("Илтимос майдонни тўдиринг!"),
  district: yup.string().notRequired(),
});
