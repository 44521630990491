import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import useSidebarStore from "../../../zustand/useStore";
import { useHooks } from "./useHooks";
import Tables from "../tables/province-table";
import FilterComponent from "components/filter-components";

export default function RegionFarmersList() {
  const year = useSidebarStore((state) => state.selectId);
  const {
    getRegionList,
    isLoading,
  } = useHooks();

  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          Республика бўйича {year} йил пахта ҳосили етиштирувчи ф/х сони ва улар
          зиммасидаги қолдиқ
        </H1>
      </Wrapper>
      <FilterComponent
        titleName={"Республика"}
        provinsiya
      />
      <Tables
        data={getRegionList}
        isLoading={isLoading}
        path={"region/"}
        hududName={"Вилоят"}
        storege={"region"}
      />
      
    </Container>
  );
}
