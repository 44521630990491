import styled from "styled-components";
import { allColors } from "./colors";

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;
export const H1 = styled.h1`
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: ${allColors.shriftColor};
`;

export const H3 = styled.h3`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: ${allColors.shriftColor};
`;

export const Cards = styled.div`
  box-sizing: border-box;
  background-color: ${allColors.whiteColor};
  border-radius: 16px;
  padding: 24px;
`;

export const HR = styled.hr`
  margin: 1rem 0;
  color: inherit;
  height: 1px;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
`;

export const Container = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  margin-bottom: 10px;
`;

// table

const getColAttrs = () => {
  return {
    "data-a-v": "middle",
    "data-f-bold": "true",
    "data-b-a-s": "thin",
    "data-b-a-c": "#FFD8D8D8",
  };
};

export const TableContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  max-height: 700px;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  min-width: 100%;
  background: rgb(38 146 94);
  color: #fff;
  box-sizing: border-box;
  position: sticky;
  top: -1px;
  z-index: 5;

  tr:first-child th:first-child {
    min-width: auto !important;
    text-align: center !important;
  }
`;
export const Th = styled("th").attrs(getColAttrs)`
  border: 1px solid #fff;
  box-sizing: border-box;
  padding: 5px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
`;
export const TBody = styled.tbody`
  width: 100%;

  /* white-space: nowrap; */
  box-sizing: border-box;
  & tr:nth-child(even) {
    background: #f9f9f9;
  }
  & tr:nth-child(2n + 1) {
    background: #e5e5e5;
  }
  & td {
    border: 1px solid #f1f1f1;
    padding: 5px 8px;
    font-weight: 400;
    font-size: 14px;
  }
  tr td {
    text-align: center;
    white-space: nowrap;
  }
  tr:first-child td:first-child {
    min-width: auto;
    text-align: center;
  }
  tr td:nth-of-type(2) {
    white-space: normal;
    text-align: start;
  }
  tr:hover {
    cursor: pointer;
    background-color: rgb(225, 230, 239);
  }
  @media (max-width: 1400px) {
    & td {
      padding: 5px 8px;
    }
  }
`;
