import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  
  return (
    <THead>
      <tr>
        <Th>№</Th>
        <Th>Вилоят</Th>
        {/* <Th>Туман</Th> */}
        <Th>Кластер сони</Th>
        <Th>Маскан сони</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
