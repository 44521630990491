// import GlobalTable from "components/table/hududlar-bo'yicha";
import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import { useHooksHudud } from "./useHooksDistrict";
import FilterComponent from "components/filter-components";
import useSidebarStore from "../../../zustand/useStore";
import HududTable from "../table";

function UruglikDistrictPage() {
  const { getDistrictList, isLoading } = useHooksHudud();
  const regionName = localStorage.getItem("region");
  const year = useSidebarStore((state) => state.selectId);

  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          {regionName} бўйича {year} йил пахта ҳосилини қабул қилиш мониторинги
        </H1>
      </Wrapper>
      <FilterComponent titleName={regionName} contract={true} />
      <HududTable
        data={getDistrictList}
        path={"/paxta-yigim/seed-cotton/region/district/farmers/"}
        isLoading={isLoading}
        storege={"district"}
        hududName={"Ҳудудлар номи"}
      />
    </Container>
  );
}

export default UruglikDistrictPage;
