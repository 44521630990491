import { allColors } from "styles/colors";
import styled from "@emotion/styled";

export const Form = styled.form`
  width: 100%;
  box-sizing: border-box;
`;
export const Title = styled.h3`
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  color: ${allColors.shriftColor};
  border-bottom: 1px solid ${allColors.shriftColor};
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: end;
  gap: 10px;
  justify-content: end;
  flex-wrap: wrap;
  .cansel {
    background-color: ${allColors.whiteColor};
    border: 1px solid ${allColors.shriftRed};
    color: ${allColors.shriftRed};
  }
  .success {
    background-color: ${allColors.mainColor};
    border: 1px solid ${allColors.mainColor};
    color: ${allColors.whiteColor};
    width: 130px;
  }
`;
