import styled from "@emotion/styled";
import { Select } from "@mantine/core";

export const FormGroupProvider = styled("div")`
  & .mantine-PasswordInput-innerInput {
    height: auto;
  }
`;

export const MantineSelect = styled(Select)`
  width: 100%;
  input {
    width: 100%;
    height: 45px;
    border: 0.8px solid #cbd1d8;
    font-family: "Inter", sans-serif;
    border-radius: 6px;
    font-feature-settings: "tnum" on, "lnum" on !important;
    &::placeholder {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #cbd1d8;
    }
  }
  label {
    margin: 5px 0;
    font-family: "Inter", sans-serif;
    color: #60676d;
    font-size: 16px;
    font-weight: 500;
  }
`;
