import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "config/react-query";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import ReactLoader from "components/react-loader";

const Loader = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <ReactLoader />
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.Suspense fallback={Loader}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colors: {
              teal: [
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
                "#08705F",
              ],
            },
          }}
        >
          <Notifications position="top-right" />
          <App />
        </MantineProvider>
      </QueryClientProvider>
    </React.Suspense>
  </React.StrictMode>
);
