import React from "react";
import { useLocation } from "react-router-dom";
import { THead, Th } from "styles/style";

function TableHeader({ hududName }) {
  const location = useLocation();
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>{hududName ?? "Ҳудудлар номи"}</Th>
        <Th rowSpan={2}>СТИР</Th>
        <Th rowSpan={2}>
          {params?.contract === "fyuchers" || params?.contract === "spot"
            ? "Шартнома буйича, тн"
            : "Ялпи хосил, тн"}
        </Th>
        <Th colSpan={2}>Бир кунда</Th>
        <Th colSpan={2}>Мавсум бошидан</Th>
        <Th rowSpan={2}>
          {params?.contract === "fyuchers" || params?.contract === "spot"
            ? "Шартномага нисбатан қолдиқ, тн"
            : "Ялпи хосилга нисбатан қолдиқ, тн"}
        </Th>
        <Th rowSpan={2}>Шартнома имзолаган кластер номи</Th>
        <Th rowSpan={2}>СТИР</Th>
        <Th rowSpan={2}>Узилиш (кун:соат:дақиқа)</Th>
      </tr>
      <tr>
        <Th>тн</Th>
        <Th>фоиз</Th>
        <Th>тн</Th>
        <Th>фоиз</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
