import React, { useState, useEffect } from "react";
import { Button, ButtonContainer, DateWrap, Title } from "./style";
import { CgSoftwareDownload } from "react-icons/cg";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import addChildTitleForTableExcel from "utils/addChildTitleForTableExcel";
import TableToExcel from "@linways/table-to-excel";
// import { useDownloadExcel } from "react-export-table-to-excel";
import { RiFileExcel2Line } from "react-icons/ri";

const FixBugRender = ({ excelParam, opened, close }) => {
  const { fileName, excelTableName, tableId } = excelParam;

  const [newTable, setNewTable] = useState(null);
  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: newTable,
  //   filename: "Users table",
  //   sheet: "Users",
  // });
  const getExcelFile = () => {
    if (newTable) {
      TableToExcel.convert(newTable, {
        name: fileName + ".xlsx",
        sheet: {
          name: fileName,
        },
      });
    }
  };
  useEffect(() => {
    const attrs = {
      "data-a-v": "middle",
      "data-a-h": "center",
      "data-f-bold": "true",
      "data-a-wrap": "true",
    };
    const table = document.getElementById(tableId)
      ? document.getElementById(tableId).cloneNode(true)
      : null;
    const nethead = table && table.getElementsByTagName("thead")[0];
    nethead &&
      addChildTitleForTableExcel(document, nethead, [
        {
          attributes: { colspan: 12 },
          parent: "tr",
          childText: " ",
          tag: "th",
        },
        {
          attributes: { colspan: 12, "data-f-sz": 14, ...attrs },
          parent: "tr",
          childText: "МАЪЛУМОТ",
          tag: "th",
        },
        {
          attributes: { colspan: 12, "data-f-sz": 14, ...attrs },
          parent: "tr",
          childText: excelTableName,
          tag: "th",
        },
      ]);
    setNewTable(table);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);
  return (
    <Modal opened={opened} onClose={close} centered>
      <Title>Ҳисоботни кўчириб олиш</Title>
      <DateWrap>
        <p>{fileName + ".xlsx"}</p>
      </DateWrap>
      <ButtonContainer>
        <Button onClick={getExcelFile}>
          <CgSoftwareDownload size={16} style={{ marginRight: "7px" }} />{" "}
          Кўчириб олиш
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
function ModalDownload({ excelParam }) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button onClick={open} id={"download"}>
        <CgSoftwareDownload size={16} style={{ marginRight: "7px" }} />
        <RiFileExcel2Line size={20}/>
      </Button>
      <FixBugRender excelParam={excelParam} opened={opened} close={close} />
    </>
  );
}

export default ModalDownload;
