import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import useSidebarStore from "../../../zustand/useStore";
import OrdinaryTable from "../components/tebles/ordinaryTable";
import { useHooks } from "./useHooks";

export default function DistrictDislocationsList() {
  const year = useSidebarStore((state) => state.selectId);
  const { getDistrictsList, isLoading } = useHooks();
  const regionName = localStorage.getItem("region");
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          {regionName} бўйича {year} йил пахта ҳосилини қабул қилиш мониторинги
        </H1>
      </Wrapper>
      <OrdinaryTable
        data={getDistrictsList}
        isLoading={isLoading}
        path={"/dislocations/region/district/"}
        hududName={"Туман"}
        storege={"district"}
      />
    </Container>
  );
}
