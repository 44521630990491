
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getAllRequest } from "shared/modules/getAllRequest";
export function useHooks() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: getDistrictsList = [],
    isLoading,
  } = useQuery(
    ["get_dislocation-districts_list-added"],
    () => getAllRequest(`/tools/dislocation/districts/list/${id}/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  return {
     getDistrictsList,
    isLoading,
  };
}
