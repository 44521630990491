import React from "react";
import { TBody } from "styles/style";
import { useNavigate } from "react-router-dom";
import { TableCol, TFoot } from "../style";

function TableBody({ data, path, storege, deleteMutate }) {
  const navigate = useNavigate();
  const clickTable = (id, name) => {
    if (storege) {
      localStorage.setItem(storege, name);
    }
    navigate(`${path ? path + id : id}`);
  };
  return (
    <>
      <TBody>
        {data?.length === 0 && (
          <tr>
            <td colSpan={7}>Маълумот йўқ</td>
          </tr>
        )}
        {data?.map((item, index) => (
          <tr key={item.id} onClick={() => clickTable(item.id, item.name)}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.clusters_count}</td>
            <td>{item.place_count}</td>
          </tr>
        ))}
        {data ? null : (
          <tr>
            <td colSpan={4}>Бу фермер хали пунктга пахта топширмаган</td>
          </tr>
        )}
      </TBody>
      <TFoot>
        <tr>
          <TableCol colSpan={2}>Жами:</TableCol>

          <TableCol>
            {data?.reduce((sum, item) => sum + item.clusters_count, 0)}
          </TableCol>
          <TableCol>
            {data?.reduce((sum, item) => sum + item.place_count, 0)}
          </TableCol>
        </tr>
      </TFoot>
    </>
  );
}

export default TableBody;
