import React from "react";
import { Link } from "react-router-dom";
import { TBody } from "styles/style";
// import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Fill } from "react-icons/ri";
import jwt_decode from "jwt-decode";
// import { TableCol, TFoot } from "../../style";

function TableBody({ data, path, storege, deleteMutate }) {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  let decoded = "";
  if (userInfo) {
    decoded = jwt_decode(userInfo.access);
  } // const navigate = useNavigate();
  // const clickTable = (id, name) => {
  //   if (storege) {
  //     localStorage.setItem(storege, name);
  //   }
  //   // navigate(`${path ? path + id : id}`);
  // };
  return (
    <>
      <TBody>
        {data?.length === 0 && (
          <tr>
            <td colSpan={10}>Маълумот йўқ</td>
          </tr>
        )}
        {data?.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.region_name}</td>
            <td>{item.district_name}</td>
            <td>{item.cluster_name}</td>
            <td>{item.place_name}</td>
            <td style={{ whiteSpace: "normal" }}>{item.farmer_name}</td>
            <td style={{ whiteSpace: "normal" }}>{item.farmer_tax_number}</td>
            <td>{item?.distance}</td>
            <td>{item?.farmer_plan ?? "-"}</td>
            <td>
              <Link to={`/dislocations/edit/${item?.id}`}>edit</Link>
            </td>
            {decoded?.group === "SUPERADMIN" && (
              <td>
                <RiDeleteBin6Fill
                  color="red"
                  size={20}
                  onClick={() => deleteMutate(item.id)}
                />
              </td>
            )}
          </tr>
        ))}
      </TBody>
      {/* <TFoot>
        <tr>
          <TableCol colSpan={2}>Жами:</TableCol>

          <TableCol>-</TableCol>
          <TableCol>{data?.reduce((sum, item) => sum + item.clusters_count, 0)}</TableCol>
          <TableCol></TableCol>
        </tr>
      </TFoot> */}
    </>
  );
}

export default TableBody;
