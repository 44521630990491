import dayjs from "dayjs";
import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FormGroupProvider, MantineDatePicker } from "./style";
function DateRangePicker({ label, dateChange, initialDates }) {
 
  return (
    <FormGroupProvider>
      <MantineDatePicker
        icon={<FaRegCalendarAlt />}
        label={label || "Оралиқ сана"}
        placeholder={"Танланг"}
        monthLabelFormat="DD MMM YYYY"
        valueFormat="DD.MM.YYYY"
        value={initialDates}
        // defaultValue={new Date()}
        mx="auto"
        type="range"
        onChange={dateChange}
        ariaLabels
        maxDate={dayjs(new Date()).endOf("days").subtract(0, "days").toDate()}
      />
    </FormGroupProvider>
  );
}

export default DateRangePicker;
