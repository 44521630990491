import { useMutation, useQuery } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { getAllRequest } from "shared/modules/getAllRequest";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useSidebarStore from "../../zustand/useStore";

export function useHooks() {
  const navigate = useNavigate();
  const year = useSidebarStore((state) => state.selectId);
  const form = useForm();
  const regionId = useWatch({
    control: form.control,
    name: "region",
  });
  const districtId = useWatch({
    control: form.control,
    name: "district",
  });
  const { mutate, isLoading } = useMutation(
    () =>
      getAllRequest("/api/farmer-reports/", {
        year: year,
        region: regionId ? regionId : undefined,
        district: districtId ? districtId : undefined,
      }),
    {
      onSuccess: (res) => {
        window.open(
          process.env.REACT_APP_API_ROOT + "/" + res?.data?.path,
          "_blank"
        );
        // form.reset();
        console.log(res, "resss");
        // notifications.show({
        //   title: `Эхсел юклаш`,
        //   message: `Муваффақиятли қўшилди!`,
        //   color: "teal",
        // });
      },
      onError: (err) => {
        // console.log(err, "errrt");
        notifications.show({
          title: `Эхсел юклаш`,
          message: err?.response?.data?.detail || "Хатолик юз берди!!!",
          color: "red",
        });
        if (err?.response?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: regionOption = [] } = useQuery(
    ["get_region"],
    () => getAllRequest(`/tools/regions/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: districtOption = [] } = useQuery(
    ["get_district_add_users", regionId],
    () => getAllRequest(`/tools/districts/${regionId}/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: regionId ? true : false,
    }
  );
  const onSubmit = (data) => {
    mutate();
  };

  return {
    form,
    onSubmit,
    navigate,
    isLoading,
    regionOption,
    districtOption,
  };
}
