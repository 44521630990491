import React from "react";
import { Container, H1, Wrapper } from "styles/style";
// import GlobalTable from "components/table/hududlar-bo'yicha";
import { useHooksHudud } from "./useHooksHudud";
import FilterComponent from "components/filter-components";
import useSidebarStore from "../../../zustand/useStore";
import HududTable from "../table";

function UrugchilikRegionPage() {
  const {
    getRegionList,
    isLoading,
  } = useHooksHudud();
  const year = useSidebarStore((state) => state.selectId);

  // location.search?.replace("?page=", "")
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px" }}
      >
        <H1>
          Республика бўйича {year} йил уруғлик пахта ҳосилини қабул қилиш мониторинги
        </H1>
      </Wrapper>
      <FilterComponent
        titleName={"Республика"}
        contract={true}
      />
      <HududTable
        data={getRegionList}
        isLoading={isLoading}
        path={"region/district/"}
        storege={"region"}
      />
    </Container>
  );
}

export default UrugchilikRegionPage;
