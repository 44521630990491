import styled from "@emotion/styled";
import { allColors } from "styles/colors";

export const ModalContent = styled("div")`
  margin: 0 !important;
  background: white;
  border-radius: 5px;
  padding: 30px 25px;
  box-shadow: ${({ isOpen }) =>
    isOpen ? "-5px 0 15px rgba(0, 0, 0, 0.05)" : "none"};
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transition: all 0.2s ease-out;
  transform: translateX(-50%) translateY(-50%);
  max-width: 375px;
  width: 90%;
  z-index: 1000;
`;
export const Title = styled("h2")`
  color: #273d56;
  position: relative;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  margin: 0;
`;
export const DateWrap = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
  margin-bottom: 30px;
  p {
    margin: 0;
  }
  input {
    max-width: 151px;
  }
  @media (max-width: 417px) {
    input {
      max-width: 220px;
    }
    & > div {
      margin-bottom: 5px;
    }
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 100%;
  }
`;

export const Button = styled.button`
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  background-color: rgb(250, 251, 253);
  border: 1px solid rgba(46, 56, 76, 0.37);
  border-radius: 5px;
  color: rgb(46, 56, 76);
  cursor: pointer;
  font-size: 14px;
  height: 38px;
  outline: none;
  padding: 0px 10px;
  transition: all 300ms ease-out 0s;
  margin-left: 0px;
  svg {
    color: green;
  }
  &:hover {
    background-color: ${allColors.mainColor};
    color: white;
    svg {
      color: white;
    }
  }
`;
// export const MinusIcon = styled(Minus)`
//   @media (max-width: 417px){
//     display: none;
//   }
// `
