import styled from "styled-components";
import { allColors } from "styles/colors";

export const TFoot = styled.tfoot`
  tr {
    background-color: rgb(38 146 94);
  }
  & tr td {
    border: 1px solid #f1f1f1;
    padding: 5px 8px;
    font-size: 14px;
    color: ${allColors.whiteColor};
    font-weight: 500;
    border-collapse: collapse;
    text-align: center;
    white-space: nowrap;
  }
  tr:first-child td:first-child {
    text-align: start !important;
  }
`;
