import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>Фермерлар номи</Th>
        <Th rowSpan={2}>СТИР</Th>
        <Th rowSpan={2}>Режа тоннаси</Th>
        <Th colSpan={4}>Бир кунда</Th>
        <Th colSpan={4}>Мавсум бошидан</Th>
        <Th rowSpan={2}>"Узилиш (соат дақиқа)"</Th>
      </tr>
      <tr>
        <Th>физик вазнда</Th>
        <Th>конд. вазнда</Th>
        <Th>скидка</Th>
        <Th>накидка</Th>
        <Th>физик вазнда</Th>
        <Th>конд. вазнда</Th>
        <Th>скидка</Th>
        <Th>накидка</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
