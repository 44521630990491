import React from "react";
import { TBody } from "styles/style";
import { TFoot } from "../style";

function TableBody({ data }) {
  return (
    <>
      <TBody>
        {data?.data?.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td style={{ textAlign: "center" }}>{item.system ?? "-"}</td>
            <td>{item.regdate}</td>
            <td>{item.docnum}</td>
            <td>{item?.fullweight}</td>
            <td>{item.taraweight}</td>
            <td>{item.net}</td>
            <td>{item.zharvest}</td>
            <td>{item.r1}</td>
            <td>{item.r2}</td>
            <td>{item.place_name ?? "-"}</td>
            <td>
              {item.pk17 ? (
                <a href={item?.pk_url} target="_blank">
                  {item.pk17}
                </a>
              ) : (
                "-"
              )}
            </td>
            <td>{item.pk17_sign_cluster ?? "-"}</td>
            <td>{item.pk17_sign_farmer ?? "-"}</td>
            <td>{item.indate}</td>
            <td>{item.outdate}</td>
            <td>-</td>
          </tr>
        ))}
        <tr></tr>
      </TBody>

      <TFoot>
        {data ? (
          <tr>
            <td colSpan={2}>Жами:</td>
            <td></td>
            <td></td>
            <td>{data?.totals?.total_fullweight ?? 0}</td>
            <td>{data?.totals?.total_taraweight ?? 0}</td>
            <td>{data?.totals?.total_net ?? 0}</td>
            <td>{data?.totals?.total_zharvest ?? 0}</td>
            <td>{data?.totals?.average_r1 ?? 0}</td>
            <td>{data?.totals?.average_r2 ?? 0}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        ) : (
          <tr>
            <td>Бу фермер хали пунктга пахта топширмаган</td>
          </tr>
        )}
      </TFoot>
    </>
  );
}

export default TableBody;
