
import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  
  return (
    <THead>
      <tr>
        <Th rowSpan="2">№</Th>
        <Th rowSpan="2">Худудлар</Th>
        <Th colSpan="2">шартнома</Th>
        <Th colSpan="2">50% гача. тн</Th>
        <Th colSpan="2">70% гача. тн</Th>
        <Th colSpan="2">90% гача. тн</Th>
        <Th colSpan="2">100% гача. тн</Th>
        <Th colSpan="2">бажарган</Th>
      </tr>
      <tr>
        <Th>сони</Th>
        <Th>тн</Th>
        <Th>сони</Th>
        <Th>қолди</Th>
        <Th>сони</Th>
        <Th>қолди</Th>
        <Th>сони</Th>
        <Th>қолди</Th>
        <Th>сони</Th>
        <Th>қолди</Th>
        <Th>сони</Th>
        <Th>орт. тн</Th>
      </tr>

    </THead>
  );
}

export default TableHeader;
