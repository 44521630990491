import { useMutation, useQuery } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { getAllRequest } from "shared/modules/getAllRequest";
import { useForm, useWatch } from "react-hook-form";
import { postAllRequest } from "shared/modules/postAllRequiest";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./form.schema";
import { useNavigate } from "react-router-dom";

export function useHooks() {
  const navigate = useNavigate();
  const form = useForm({
    resolver: yupResolver(formSchema),
  });
  const regionId = useWatch({
    control: form.control,
    name: "region",
  });
  const { mutate } = useMutation(
    (data) => postAllRequest("/auth/users-create/", data),
    {
      onSuccess: (res) => {
        form.reset();
        // console.log(res, "resss");
        notifications.show({
          title: `Маскан қўшиш`,
          message: `Муваффақиятли қўшилди!}`,
          color: "teal",
        });
        navigate(-1);
      },
      onError: (err) => {
        // console.log(err, "errrt");
        notifications.show({
          title: `Маскан қўшиш`,
          message: err?.response?.data?.detail || "Хатолик юз берди!!!",
          color: "red",
        });
        if (err?.response?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: getGroupsList = [] } = useQuery(
    ["get_position-groups"],
    () => getAllRequest(`/auth/groups/`),
    {
      select: (res) => {
        const payload = res?.data?.map((item) => ({
          label: item.name,
          value: String(item.id),
        }));
        return payload;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: regionOption = [] } = useQuery(
    ["get_region"],
    () => getAllRequest(`/tools/regions/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { data: districtOption = [] } = useQuery(
    ["get_district_add_users", regionId],
    () => getAllRequest(`/tools/districts/${regionId}/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
      enabled: regionId ? true : false,
    }
  );
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("company_name", data?.company_name);
    formData.append("tin", data?.tin);
    formData.append("pinfl", data?.pinfl);
    formData.append("region", data?.region);
    if (data?.district) {
      formData.append("district", data?.district);
    }
    const rolesArray = Array.isArray(data?.roles) ? data?.roles : [data?.roles];
    formData.append("roles", JSON.stringify(rolesArray));
    mutate(formData);
  };

  return {
    form,
    onSubmit,
    navigate,
    getGroupsList,
    regionOption,
    districtOption,
  };
}
