import React from "react";
import { Container, H1, Wrapper } from "styles/style";
import { Button, Grid } from "@mantine/core";
import { SelectContainer } from "components/select";
import { useHooks } from "./useHooks";
import { RiFileExcel2Fill } from "react-icons/ri";
import { ButtonWrapper, Form, Title } from "./style";

export default function ExcelYuklash() {
  const { form, regionOption, districtOption, onSubmit, isLoading } =
    useHooks();
  return (
    <Container>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px 20px" }}
      >
        <H1>Ҳисоботлар</H1>
      </Wrapper>
      <Wrapper
        style={{ marginTop: "30px", background: "#fff", padding: "10px 20px" }}
      >
        <Title>Фермерлар ҳисоботини юклаш</Title>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Вилоят"
                placeholder="Вилоят"
                name="region"
                data={[
                  {
                    label: "Barchasi",
                    value: "",
                  },
                  ...regionOption,
                ]}
                searchable={false}
                onChange={() => form.setValue("district", null)}
              />
            </Grid.Col>
            <Grid.Col span={12} xl={3} lg={4} md={4}>
              <SelectContainer
                control={form.control}
                label="Туман"
                placeholder="Туман"
                name="district"
                data={[
                  {
                    label: "Barchasi",
                    value: "",
                  },
                  ...districtOption,
                ]}
                searchable={false}
              />
            </Grid.Col>

            <Grid.Col span={"auto"}>
              <ButtonWrapper style={{ height: "100%" }}>
                <Button
                  leftIcon={<RiFileExcel2Fill />}
                  type="submit"
                  className="success"
                  loading={isLoading}
                >
                  Юклаш
                </Button>
              </ButtonWrapper>
            </Grid.Col>
          </Grid>
        </Form>
      </Wrapper>
    </Container>
  );
}
