import * as yup from "yup";

export const formSchema = yup.object().shape({
  region: yup.string().required("Илтимос майдонни тўдиринг!"),
  district: yup.string().required("Илтимос майдонни тўдиринг!"),
  cluster_tax_number: yup.string().required("Илтимос майдонни тўдиринг!"),
  place: yup.string().required("Илтимос майдонни тўдиринг!"),
  farmer_tax_number: yup.string().required("Илтимос майдонни тўдиринг!"),
  // farmer_plan: yup.string().required("Илтимос майдонни тўдиринг!"),
  distance: yup.string().required("Илтимос майдонни тўдиринг!"),
});
