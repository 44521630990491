import React from "react";
import { THead, Th } from "styles/style";
// import jwt_decode from "jwt-decode";

function TableHeader({hududName}) {
  // const userInfo = JSON.parse(localStorage.getItem("user"));
  // let decoded = "";
  // if (userInfo) {
  //   decoded = jwt_decode(userInfo.access);
  // }
  return (
    <THead>
      <tr>
        <Th>№</Th>
        <Th>{hududName}</Th>
        <Th>Кластерлар сони</Th>
        <Th>Масканлар сони</Th>
        <Th>Хўжалик сони</Th>
        <Th>Режа ялпи хосил, тн</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
