import React from "react";
import { THead, Th } from "styles/style";

function TableHeader() {
  return (
    <THead>
      <tr>
        <Th rowSpan={2}>№</Th>
        <Th rowSpan={2}>Оператор</Th>
        <Th colSpan={2}>Юк хати</Th>
        <Th colSpan={3}>Физик вазни, кг</Th>
        <Th rowSpan={2}>Кондицион вазни</Th>
        <Th colSpan={2}>Лабор-я хулосаси</Th>
        <Th rowSpan={2}>
          Маскан
          <br /> номи
        </Th>
        <Th rowSpan={2}>ПК-17 рақами</Th>
        <Th colSpan={2}>ПК-17 имзолаш ҳолати</Th>
        <Th colSpan={2}>Кайд этилган вақт</Th>
        <Th>Серверга юборилган</Th>
      </tr>
      <tr>
        <Th>санаси</Th>
        <Th>рақами</Th>
        <Th>брутто</Th>
        <Th>тара</Th>
        <Th>нетто</Th>
        <Th>ифлослик </Th>
        <Th>намлик</Th>
        <Th>Кластер</Th>
        <Th>Фермер</Th>
        <Th>кириш</Th>
        <Th>чиқиш</Th>
        <Th>1С га</Th>
      </tr>
    </THead>
  );
}

export default TableHeader;
