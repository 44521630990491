import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import useSidebarStore from "../../../zustand/useStore";

import { getAllRequest } from "shared/modules/getAllRequest";

export function useHooksTerritory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const object = new URLSearchParams(location.search);
  const params = Object.fromEntries(object.entries());

  const year = useSidebarStore((state) => state.selectId);

  const { data: getFermerList = [], isLoading } = useQuery(
    [`get_fermer_urug_list_${id}`, year, params.end_date, params?.pk17],
    () =>
      getAllRequest(`/api/seeds/${id}/`, {
        year: year,
        unit_type: params.unit_type ?? undefined,
        start_date: params.start_date ?? undefined,
        end_date: params.end_date ?? undefined,
        pk17: params?.pk17 ?? undefined,
      }),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );

  return {
    getFermerList,
    isLoading,
  };
}
