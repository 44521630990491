import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { deleteAllRequest } from "shared/modules/deleteAllRequest";
import { getAllRequest } from "shared/modules/getAllRequest";
export function useUsersListHooks() {
  const navigate = useNavigate();
  const {
    data: getUsersList = [],
    isLoading,
    refetch,
  } = useQuery(
    ["get_users_list-added"],
    () => getAllRequest(`/auth/users-list/`),
    {
      select: (res) => {
        return res?.data;
      },
      onError: (err) => {
        if (err?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  const { mutate: deleteMutate } = useMutation(
    (id) => deleteAllRequest(`/auth/users/delete/${id}/`),
    {
      onSuccess: () => {
        refetch();
        notifications.show({
          title: `Фойдаланувчи ўчириш`,
          message: `Муваффақиятли ўчирилди!`,
          color: "teal",
        });
      },
      onError: (err) => {
        // console.log(err, "errrt");
        notifications.show({
          title: `Фойдаланувчи ўчириш`,
          message: err?.response?.data?.detail || "Хатолик юз берди!!!",
          color: "red",
        });
        if (err?.response?.status === 401) {
          navigate("/");
        }
      },
    }
  );
  return {
    getUsersList,
    isLoading,
    deleteMutate,
  };
}
